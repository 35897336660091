import { Header, Image, Grid, Segment } from "semantic-ui-react"
import styles from "./About.module.css"
import Biopic from "../../assets/images/foto_MateoLugo.jpg"
import Trees from "../../assets/images/foto_TheProject.jpg"

const about = () => {
  return (
    <Segment className={styles.Segment} vertical>
      <Grid columns="two" centered>
        <Grid.Row id="about" className={styles.Row}>
          <Grid.Column width="four" floated="right">
            <Image src={Biopic} size="medium" />
          </Grid.Column>
          <Grid.Column width="four" floated="left">
            <p>
              Hello! My name is Mateo, I am a guitarist and Iove to improvise. I
              love the challenge of coming up with new pieces of music on the
              spot. You never know what's gonna come out! It wasn't always this
              fun for me though. Some years ago, I felt like I got stuck in my
              playing and that I wasn't making any more progress. I felt like my
              ideas were stale.
            </p>
            <p>
              To solve this I came up with The Guitar Tarot. To this day,
              running through a couple guitar tarot exercises is still the
              quickest way to get myself out of familiar territory and explore
              new sonic possiblities. And to boot, it continues to deepen my
              knowledge of the fretboard in ways no other system has before.
            </p>
            <p>
              Perhaps you feel like you need a new approach. Perhaps you've
              tried the CAGED system and you want to go beyond. Perhaps you just
              want to try something a little different. Either way, I hope you
              enjoy The Guitar Tarot as much as I have and it leads you to new
              musical horizons. Cheers.
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={styles.Row}>
          <Grid.Column width="four" floated="right">
            <Header as="h3" inverted>
              What is the Guitar Tarot?
            </Header>
            <p>
              The Guitar Tarot is a set of exercises designed to immediately get
              you out of your comfort zone. By shuffling note cards, interval
              cards, and chord cards, you run into sound combinations outside of
              your muscle memory, outside of the chord and scale patterns you
              are used to. This stimulates your ear and your imagination to
              explore other sonic possibilities all the while challenging your
              knowledge of the guitar fretboard.
            </p>
          </Grid.Column>
          <Grid.Column width="four" floated="left">
            <Image src={Trees} size="medium" centered />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default about
