import { Container, Header, Segment, Image } from "semantic-ui-react"
import styles from "./Masthead.module.css"
import Logo from "../../assets/images/Logo_GT.png"

const masthead = () => {
  return (
    <Segment className={styles.Segment} vertical textAlign="center">
      <Container text>
        <Header as="h1" className={styles.Welcome}>
          Welcome to
        </Header>
        <Image src={Logo} size="medium" bordered centered></Image>
        <Header as="h3" className={styles.Sub}>
          The deepest method of learning the guitar neck
        </Header>
      </Container>
    </Segment>
  )
}

export default masthead
